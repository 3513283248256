import React from "react";
import style from "./aboutus.module.css";
import { Col, Row } from "react-bootstrap";


const Championabout = () => {

  return (
    <section id="coresection">
   
    <div className={style.champabout}>
        
     
      <div className="container">
        <div className={style.beliefsection}> 
     
        <h2 className={style.champaboutheading}>Our Core Beliefs</h2>
        <Row className={style.champtext}>
          <Col md={6} className="mb-4">
            <h1>Respect For Life
            </h1>
            <p>
            All life is a gift from the Creator that should be respected and protected at every stage, age, or condition it’s found in from conception to natural death. 

            </p>
          </Col>
          <Col md={6} className="mb-4">
            <h1>Genuine Concern Missing</h1>
            <p>
            To genuinely care for a woman’s well-being, it’s crucial to offer support that goes beyond temporary solutions, which often lead to long-term suffering.
            </p>
          </Col>
        </Row>
        <Row className={style.champtext}>
          <Col md={6} className="mb-4">
            <h1>Problem Incorrectly Identified
            </h1>
            <p>
            The fact that many women undergo multiple abortions demonstrates that the broader issues in their lives remain unresolved.
            </p>
          </Col>
          <Col md={6} className="mb-4">
            <h1>Ideological Unity
            </h1>
            <p>
           Despite ideological differences, everyone should agree that reducing the number of lives lost to abortion benefits us all.
            </p>
          </Col>
        </Row>
        <section id="doitsection">
        <div className={style.champara}>
        <h2 className={style.champaboutheading}>How We Do It </h2>
        <p>
        Our crowdfunding platform allows anyone to easily donate any amount to these small, dedicated, highly effective organizations to address their ongoing needs for funds to support those seeking life-affirming alternative and post-abortive care.
        </p>
      </div>
      </section> 
      </div>
      </div>

    
    </div>
    </section>
  );
};

export default Championabout;
