

import React, { useState } from "react";
import style from "./donation.module.css";
import {
  
  Col,
  
  Row,
 
} from "react-bootstrap";


import {useNavigate} from "react-router-dom";


import { toast } from "react-toastify";



import useGetUser from "../../customHook/useGetUser";
import { Helmet } from 'react-helmet-async';

const Adminpayment = () => {
 
  useGetUser();
 
  const navigate = useNavigate();
  

 const [amount, setSelectedAmount] = useState(""); // State for the selected amount radio button
  const [selectedFrequency, setSelectedFrequency] = useState("month"); // State for the selected frequency radio button

  const handleAmountChange = (e) => {
    setSelectedAmount(e.target.value); // Update the selected amount
  };

  const handleFrequencyChange = (e) => {
    setSelectedFrequency(e.target.value); // Update the selected frequency
  };
  const handleDonate = () => {
    if (!amount) {
      toast.error("Please select a  amount for proceed the payment"); // Show error toast if no amount is selected
      return;
    }
    const clearamount=amount.replace(/,/g, '')
    
        localStorage.setItem('amount', clearamount);
        localStorage.setItem('frequency', selectedFrequency);
        navigate("/information/")
  
  };


 
return (
  <>
  <Helmet>
  <title>Donate-to-us - I Stand With The Forgotten Women</title>
  <meta name="description" content="Support our mission by choosing a donation amount and frequency. Help ensure those in need are not forgotten. Your generosity matters!"/>
  <meta name="keywords" content="Donation amount,Custom donation,Donation frequency,One-time donation,Monthly donation,Checkout,Thank you,Financial contribution,Support,Charitable giving"/>
</Helmet>
    <div className={style.donationcontainer}>
      <div className="container">
        <div className={style.inforcol}>
          <Row className="align-items-center">
          <Col md={12} xl={7} lg={12}>
              <div className="p-3">
                <div className={style.paymentForm}>
                  <div className={style.paymentHead}>Choose A Donation Amount</div>
                  <div className={style.donationpayment}>

                  <div className={style.paymentcontainer}>

                  <Row>
                            <Col md={6}>

                            <div
                              className={
                                amount === "15"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="15"
                                      checked={amount === "15"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$15</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                            </Col>
                           
                           <Col md={6}>
                           <div
                              className={
                                amount === "50"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="50"
                                      checked={amount === "50"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$50</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                           </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                            <div
                              className={
                                amount === "100"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="100"
                                      checked={amount === "100"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$100</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                            </Col>

                            <Col md={6}>

                            
                            <div
                              className={
                                amount === "200"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="200"
                                      checked={amount === "200"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$200</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                            <div
                              className={
                                amount === "500"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="500"
                                      checked={amount === "500"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$500</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                            </Col>

                            <Col md={6}>

                            <div
                              className={
                                amount === "1000"
                                  ? style.selectedLabel
                                  : style.donateinputcontainer
                              }>
                              <label>
                                <div className={style.innerlabel}>
                                  <div className={style.smallscreeninput}>
                                    <input
                                      type="radio"
                                      name="amount"
                                      value="1000"
                                      checked={amount === "1000"}
                                      onChange={handleAmountChange}
                                    />

                                    <span>$1000</span>
                                  </div>
                                </div>
                              </label>
                            </div>
                             </Col>
                          </Row>
                 
                    
                    </div>  

                    <div className={style.amountdonation} >
                  <div className={style.labellink}>
       
          Enter a custom donation amount

      </div>
    
        <div className={style.customAmountInput}>
      <span className={style.currency}>$</span>
          <input
            type="text"
            value={amount}
            placeholder="Enter custom amount"
            onChange={(e) => setSelectedAmount(e.target.value)}
            className={style.inputboxtext}
          />
        </div>
    
                  </div>
                  <h3>Choose a donation frequency</h3>
                  <div className={style.donateradio}>
                    <div className={selectedFrequency === "1" ? style.selectedLabel2:style.labeldonate}>
                      <div className={style.donationlabel}>
                      <label
                          className={
                            style.labelcontent
                          }>
                          <input
                            type="radio"
                            name="frequency"
                            value="1"
                            checked={selectedFrequency === "1"}
                            onChange={handleFrequencyChange}
                          />

                          <span>OneTime</span>
                        </label>
                      </div>
                    </div>

                    <div className={selectedFrequency === "month" ? style.selectedLabel2:style.labeldonate2}>
                      <div className={ style.donationlabel}>
                        <label
                          className={
                            style.labelcontent
                          }>
                          <input
                            type="radio"
                            name="frequency"
                            value="month"
                            checked={selectedFrequency === "month"}
                            onChange={handleFrequencyChange}
                          />

                          <span>Monthly</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={style.donationbuttoncontainer}>
              
                    <div className={style.smallscreenabutton}>
                    <button onClick={handleDonate}>Go to Checkout</button>
                    </div>
                  </div>
                 
                  </div>
                
                </div>
              </div>
            </Col>

            <Col md={12} xl={5} lg={5} xs={12} >
              <div className={style.donatethank}>
                <h1>
                  Thank You <br></br>
                  for proving they’re not forgotten
                </h1>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </>
  );
};

export default Adminpayment;