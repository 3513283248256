import React from "react";
import style from "./book.module.css";


const Modeled = () => {

  return (
    <div className={style.modeled_after_divine}>


      <div >
      
            <div className={style.content_modeled_wrapper}>
              <div className="container">
              <div className={style.wisdom_heading} >God's 'Blueprint' for Addressing Unplanned Pregnancies</div>
              <div className={`mb-0 ${style.book_text}`}>
               
                <p className={style.wisdom_text}>This ‘Blueprint’ draws inspiration from the most well-known unplanned pregnancy in history—the birth of Christ. Modeled After Another Unplanned Pregnancy (from a human perspective)  as God provided a way to prevent what could have been a crisis, this resource provides a roadmap for the church to avoid and address unplanned pregnancies with grace and wisdom. It’s time to expand our mission from being Pro-Life to embracing a Pro-Abundant Life approach.</p>

              </div>
              </div>
            </div>
      
      </div>
    </div>

  );
};

export default Modeled;
