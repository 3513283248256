import React, { useState } from "react";
import { Button, Col, Form, Row, InputGroup, Spinner } from "react-bootstrap";
import style from "./auth.module.css";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { resetPasswordapi } from "../../apis/authApis";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ResetPassword = () => {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const [queryParameters] = useSearchParams()
   const resetPasswordtoken = queryParameters.get("token");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmePassword] = useState(false);
  const [disableButton,setDisableButton]=useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmePassword(!showConfirmPassword);
  };
  return (
    <>
     <Helmet>
        <title>ResetPassword - I Stand With The Forgotten Women</title>
        <meta name="description" content="Enter a new password to securely reset your account access. Confirm your password to complete the reset and regain access to your profile."/>
        <meta name="keywords" content="Reset password,New password,Confirm password,Password update,Back to login,Account security,Password change,User authentication,Password confirmation,Login"/>
      </Helmet>
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: "70vh" }}
    >
      <Row className="m-0 p-0 w-100">
        <Col xs={12} sm={8} md={6} lg={4} className="mx-auto p-0">
          <div className={style.authBox}>
            <h3 className="text-center mb-4">Reset Your Password</h3>
            <div className="mb-4" style={{ color: "#757575" }}>
              Enter a new password.
            </div>
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object().shape({
                newPassword: Yup.string()
                  .required("New password is required")
                  .min(8, "New password must be at least 8 characters long"),
                 
                confirmPassword: Yup.string()
                  .required("Confirm password is required")
                  .oneOf(
                    [Yup.ref("newPassword"), null],
                    "Passwords must match"
                  ),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                setDisableButton(true)

                try {
                  setStatus({ success: false });
                  dispatch(resetPasswordapi(values,resetPasswordtoken,navigate)).then(()=>setDisableButton(false))
                } catch (err) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setDisableButton(false)
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        className={
                          touched.newPassword && errors.newPassword
                            ? "is-invalid"
                            : ""
                        }
                        type={showPassword ? "text" : "password"}
                        placeholder="********"
                        id="newPassword"
                        value={values.newPassword}
                        name="newPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.newPassword && errors.newPassword
                        )}
                      />
                      <InputGroup.Text
                        style={{ cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </InputGroup.Text>
                    </InputGroup>

                    {touched.newPassword && errors.newPassword && (
                      <Form.Text className=" text-danger">
                        {errors.newPassword}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-4"
                    controlId="formBasicConfirmPassword"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        className={
                          touched.confirmPassword && errors.confirmPassword
                            ? "is-invalid"
                            : ""
                        }
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="********"
                        id="confirmPassword"
                        value={values.confirmPassword}
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                      />
                      <InputGroup.Text
                        style={{ cursor: "pointer" }}
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </InputGroup.Text>
                    </InputGroup>

                    {touched.confirmPassword && errors.confirmPassword && (
                      <Form.Text className=" text-danger">
                        {errors.confirmPassword}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Button
                    className="w-100 mt-2 authBlubtn"
                    variant="primary"
                    type="submit"
                    disabled={disableButton}
                  >
                    Submit
                    { disableButton &&
                   <Spinner
                   className="ms-3"
                     as="span"
                     animation="grow"
                     size="sm"
                     role="status"
                     aria-hidden="true"
                   />
                   }   
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="text-center mt-3 mb-4">
              Back to <Link to="/login">login</Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default ResetPassword;
