import React from "react";
import style from "./book.module.css";
import Newsletter from "../home/newsletter";
import LifeApprochNeeded from "./lifeapprochneeded";
import TheChurhMust from "./thechurchmust";
import Turnround from "./turnround";
import Modeled from "./modeled";
import Matters from "./matters";
import { Helmet } from 'react-helmet-async';
const Book = () => {
  return (
    <>
     <Helmet>
        <title>Book - I Stand With The Forgotten Women</title>
        <meta name="description" content="Explore our blueprint for addressing unplanned pregnancies with grace. Join the movement to empower faith communities to embrace a pro-abundant life mission!"/>
        <meta name="keywords" content="Divisive issue,Pastoral leadership,Pro-life,Abundant life,Unplanned pregnancies,Crowdfunding platform,Human life reverence,Biblical integrity,Compassionate resource,Faith community"/>
      </Helmet>
      <div className={style.book_top_section}>
        <div className="container">
          <div className={style.book_top_content}>
            <p>
              The most divisive issue in the church since the abolition of slavery with profound ethical, societal and spiritual implications that demand pastoral leadership.
            </p>
          
          </div>
        </div>
      </div>
      <TheChurhMust />
      <LifeApprochNeeded />
      <Modeled />
      <Matters />
      <Turnround />
      <div className="container">
        <div className={style.standcontainer}>
          <h2> Join The Movement. Stand With Us</h2>
        </div>
      </div>
      <Newsletter></Newsletter>
    </>
  );
};

export default Book;
