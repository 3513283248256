import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import style from "./home.module.css";

const CardPage = () => {
  return (
    <Container className="my-5">
      <div className={style.cardmain}>
      <h2 className={style.cardheading}>Our Impact: Support, Advocacy, Community</h2>
      <Row className={style.cardtextt}>
          <Col md={4} className="mb-md-4 mb-0">
           <h1>Policy Change</h1>
           <p>Influence legislation that takes in consideration of the need for abortion alternatives.</p>
          </Col>
          <Col md={4} className="mb-md-4 mb-0">
          <h1>Fundraising</h1>
          <p>Raising funds for organizations that affirm life: Pregnancy Resource Centers, Maternity homes, Adoption agencies
          </p>
          </Col>
          <Col md={4} className="mb-md-4 mb-0">
        <h1>Volunteer</h1>
        <p>Mobilizing a dedicated team of volunteers to provide hands-on support and advocacy.</p>
        </Col>
      
      </Row>
      </div>
    </Container>
  );
};

export default CardPage;
