import React from "react";
import style from "./homefooter.module.css";
import { Row, Col } from "react-bootstrap";

import { Link} from "react-router-dom";

const HomeFooter = () => {

  return (
 
    <div className={style.footer_main}>
      <div className={style.bigscreenfooter}>
      <div className="container">
    
      <Row className="m-0 p-0">

      <Col md={6} lg={4} >
          <img
            className={`${style.footer_logo}`}
            src="/mainlogo.svg"
            alt="bigscreenmainlogo"
          />
           <h1 className={style.footer_heading}>FOLLOW US</h1>
           <div className="d-flex">
            <Link to="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" className={style.footer_links} target="_blank"><img src="/fbsvg.svg" alt="facebook"/></Link><br />
       
            <Link to="https://www.instagram.com/istandwithforgottenwomen/" className={style.footer_linksinsta} target="_blank">  <img src="/instasvg.svg" alt="instagram"></img></Link><br />
            <Link to="https://x.com/4gottenwomen" className={style.footer_linksinsta} target="_blank">  <img src="/twitternew.png" alt="twitter"></img></Link><br />
            <Link to="https://www.youtube.com/@istandwiththeforgottenwomen" className={style.footer_linksinsta} target="_blank">  <img src="/youtube.png" alt="youtube"></img></Link><br />

            </div>
        </Col>
  
        <Col md={2} xs={12} className="text-start mb-3 mb-md-0">
          <h2 className={style.footer_heading}>Learn More</h2>
          <Link to="https://blog.istandwiththeforgottenwomen.org/" className={style.footer_links}>Blog </Link><br/>
          <Link to="/contactus/" className={style.footer_links}>Contact Us </Link><br/>
    
        <Link to="/speaker-request/" className={style.footer_links}>SpeakerRequest </Link><br/>
        <Link to="https://store.care-net.org/making-life-disciples/" target="_blank" className={style.footer_links}>Life Disciples </Link><br/>
     
        </Col>
        <Col md={2} xs={12} className="text-start text-md-start mb-3 mb-md-0">
       <a href="/book/"> <img  className="w-100" src="/abortionbook.png" alt="abortion"></img></a> 
  
         
        </Col>
      
        <Col md={2} className="d-flex justify-content-center">
      
        <a href="https://9eb34e-77.myshopify.com/collections/all" target="_blank"> <img  className={style.newbook} src="/booknew.png" alt="newbook"></img></a> 
        </Col>
        <Col md={2} xs={8} className="text-center text-md-end mt-2">
       <p className={style.footerpara}>Their</p>
       <p className={style.footerpara}>Lives</p>
       <p className={style.footerpara}>Matter</p>
       <p className={style.footerparato}>too!</p>
        </Col>
        <div className={style.footertag}>
       <p >© 2024 I Stand With The Forgotten Women. All rights reserved.</p>
 
        </div>

      </Row>
      </div>

      </div>
     <div className={style.smallscreenfooter}>

      <div className="container p-0">
    
    <Row className="m-0 p-0">

    <Col md={6}>
        <img
          className={`${style.footer_logo}`}
          src="/mainlogo.svg"
          alt="smallscreenmainlogo"
        />
         <p className={style.footerpara}>Their Lives  Matter,<span> too! </span></p>
        
      </Col>
   
      <Col md={6} className="text-start mb-3 mb-md-0 p-0">
      <div  className={style.smallscreenfootheading}>
      <div  className={style.smallscreenfoothead}>
        <h2 className={style.footer_heading}>Learn More</h2>
        <Link to="https://blog.istandwiththeforgottenwomen.org/"  className={style.footer_links}> Blog </Link><br/>
        <Link to="/contactus/" className={style.footer_links}>Contact Us </Link><br/>
    
        <Link to="/speaker-request/" className={style.footer_links}>SpeakerRequest </Link><br/>
        <Link to="https://store.care-net.org/making-life-disciples/" target="_blank" className={style.footer_links}>Life Disciples </Link><br/>
       

        </div>
        <div  className={style.smallscreenfoothead}>
          <div className="d-flex">
          <a href="/book/" style={{width:'100px'}}> <img  className="w-100" src="/abortionbook.png" alt="smallscreenabortion"></img></a>
          <a href="https://9eb34e-77.myshopify.com/collections/all" target="_blank" style={{width:"50px"}}> <img  className={style.newbook} src="/booknew.png" alt="newbookscreen"></img></a> 
          </div>

          </div>
          </div>
      </Col>
      <div className={style.follow}>
      <h1 className={style.footer_heading}>FOLLOW US</h1>
        <div className="d-flex justify-content-center">
          <Link to="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" className={style.footer_links} target="_blank">  <img src="/fbsvg.svg" alt="fb"></img></Link><br />
        
          <Link to="https://x.com/4gottenwomen" className={style.footer_linksinsta} target="_blank">  <img src="/twitternew.png" alt="tweet"></img></Link><br />
          <Link to="https://www.instagram.com/istandwithforgottenwomen/"className={style.footer_linksinsta} target="_blank">  <img src="/instasvg.svg" alt="insta"></img></Link><br />
          <Link to="https://www.youtube.com/@istandwiththeforgottenwomen"className={style.footer_linksinsta} target="_blank">  <img src="/youtube.png" alt="utube"></img></Link><br />

          </div>
          </div>
      <div className={style.footertag}>
     <p >© 2024 I Stand With The Forgotten Women. All rights reserved.</p>
 
      </div>

    </Row>
    </div>
    </div>
    </div>
  );
};

export default HomeFooter;
