import React, { useState, useRef } from "react";

import style from "./college.module.css";
import { Helmet } from "react-helmet-async";
import PhoneInput from "react-phone-input-2";

import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

import ReCAPTCHA from "react-google-recaptcha";
import { createResume } from "../../apis/hiringApis";
const Jointheprolifemovement = () => {
  const [disableButton, setDisableButton] = useState(false);
  const dispatch = useDispatch();
  
  const [captchaValue, setCaptchaValue] = useState(null);
  const fileInputRef = useRef(null);
  const recaptchaRef = useRef(null);
  const handleCaptchaChange = (values) => {
    setCaptchaValue(values); // Store reCAPTCHA response token
  };
  const siteKey = "6LctnGoqAAAAAHqW-2qC7ZUH7ghmO2k-Q56fUp_H";
  console.log("value", captchaValue);
  return (
    <div>
      <Helmet>
        <title>
          Join-the-pro-life-movement - I Stand With The Forgotten Women
        </title>
        <meta
          name="description"
          content="We're seeking a proactive virtual assistant and administrator to support our founder in raising awareness for our pro-life cause. Engage with communities, create content, and drive impactful campaigns. Apply now!"
        />
        <meta
          name="keywords"
          content="Virtual assistant,Administrator,Pro-life,Awareness campaigns,Video creation,Community engagement,Social media management,Email campaigns,Content development,Remote positions"
        />
      </Helmet>
      <img className="w-100" src="/newmike.png" alt="banner" />

      <div className="container p-0">
        <div className={style.maincollegecontainer}>
          <div className={style.mainheadingcollege}>
           
            <p>
              We are looking for a dynamic and passionate pro-life virtual
              assistant/administrator to be the primary assistant to the
              founder. Our mission is to raise awareness about supporting
              alternatives for women and couples seeking options other than
              abortion, as well as providing care for those who have experienced
              abortion.
            </p>
            <p>
              This individual will be tasked with assembling a team of pro-life
              advocates with innovative ideas and skills to help spread our
              message in every country where abortion is legal.   
            </p>

            <p>
              They will also develop systems and campaigns aimed at reaching
              difficult pro-choice communities, including young adults, African
              Americans, fraternities, sororities, nurse associations,
              schoolteachers, and more.
            </p>
          </div>
          <h1>Specifically, they will engage in:  </h1>
          <ul>
            <li>Brainstorming ideas for video creation</li>
            <li>
            Creative ways to reach church pastors, fraternities, sororities, professional groups 

            </li>
            <li>
            Search the web for video content and articles that we can retarget with our message 
            </li>
            <li>Monitor YouTube Channel responses</li>

            <li>Write blog posts
            </li>
            <li>Website updates</li>
            <li>Create and manage email campaigns
            </li>
            <li>Monitor social media accounts
            </li>
            <li>Connect with high-profile pro-life influencers
            </li>
            <li>
              Recruit Pregnancy Care Centers, Maternity Homes, and adoption agencies to join the platform
            </li>
         
          </ul>

         

          <h5>Salary to be determined.</h5>
          <h4> Fill this out to apply:</h4>

          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              captchaValue: "",
              resume: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required("Name is required"),
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }
            ) => {
              if (!captchaValue) {
                setErrors({ captcha: "Please verify the captcha" });
                setSubmitting(false);
                return;
              }

              try {
                values.captchaValue = captchaValue;

                setStatus({ success: false });
                setSubmitting(false);

                dispatch(createResume({ value: values })).then(() => {
                  console.log("values", values);
                  resetForm();
                  setFieldValue("phone", ""); // Reset phone field
                  recaptchaRef.current.reset();
                  if (fileInputRef.current) {
                    fileInputRef.current.value = ""; // Clear file input
                  }

                  setCaptchaValue(null);
                  setDisableButton(false);
                });
              } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setDisableButton(false);
              }
            }}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <div className={style.formsection}>
                <div className={style.innerformsection}>
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className={style.formgroupcontainer}>
                      <Form.Group
                        className={style.formcontainer1}
                        controlId="formBasicname">
                        <Form.Label> Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          className={`${style.customtextinput}  ${
                            touched.name && errors.name ? "is-invalid" : ""
                          }`}
                          value={values.name}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.name && errors.name)}
                        />
                        {touched.name && errors.name && (
                          <Form.Text className=" text-danger">
                            {errors.name}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group
                        className={style.formcontainer1}
                        controlId="formBasicEmail">
                        <Form.Label>Email </Form.Label>
                        <Form.Control
                          className={`${style.customemailinput}  ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                          

                          type="email"
                          placeholder="Enter email"
                          value={values.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.email && errors.email)}
                        />
                        {touched.email && errors.email && (
                          <Form.Text className=" text-danger">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                    <div className={style.formgroupcontainer}>
                      <Form.Group
                        className={style.formcontainer}
                        controlId="formBasicPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <PhoneInput
                          country={"us"} // Default to India, or change as needed
                          value={values.phone}
                          onChange={(phone) => setFieldValue("phone", phone)}
                          onBlur={handleBlur}
                          
                          inputClass={`${style.phoneconatiner}  ${
                            touched.phone && errors.phone ? "is-invalid" : ""
                          }`}
                          countryCodeEditable={false} // Disable manual editing of country code
                          enableSearch={true} // Add search functionality to the country dropdown
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                        />
                        {touched.phone && errors.phone && (
                          <Form.Text className="text-danger">
                            {errors.phone}
                          </Form.Text>
                        )}
                      </Form.Group>

                      <Form.Group
                        className={style.formcontainer}
                        controlId="formBasicresume">
                        <Form.Label>Upload Resume</Form.Label>
                        <Form.Control
                          type="file"
                          name="file"
                          ref={fileInputRef}
                          onChange={
                            (event) =>
                              setFieldValue(
                                "file",
                                event.currentTarget.files[0]
                              ) // Fix file handling
                          }
                          accept=".pdf, .doc, .docx"
                          className={`${style.customresumeinput}  ${
                            touched.file && errors.file ? "is-invalid" : ""
                          }`}
                        />
                        {touched.file && errors.file && (
                          <Form.Text className="text-danger">
                            {errors.file}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                    <div className={style.recaptchacontainer}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={siteKey}
                        // value={captchaValue}
                        onChange={handleCaptchaChange}
                      />
                      {errors.captcha && (
                        <Form.Text className="text-danger">
                          {errors.captcha}
                        </Form.Text>
                      )}
                    </div>
                    <div className={style.buttoncontainer}>
                      <Button
                        className="w-100 authBlubtn"
                        variant="primary"
                        type="submit"
                        disabled={disableButton}>
                        Submit
                        {disableButton && (
                          <Spinner
                            className="ms-3"
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>

        </div>
      </div>
    </div>
  );
};

export default Jointheprolifemovement;
