import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "./home.module.css";
import { Link } from "react-router-dom";

const Movement = () => {
  return (
    <div className={style.movementconatiner}>
      <div className={style.movecont2}>

        <div className="container">
          
    <div className={style.movetextConatiner}>
        <div className={style.headingmove}> 
        <h1>We Need You!</h1>
   
        </div>
        <Row className={style.movetext}>
          <Col md={4} className={style.movedonate}>
           <h1>Donate</h1>
           <p>Your generous contributions help us provide vital support services to women and couples in need.</p>
          </Col>
          <Col md={4} className={style.movedonate}>
          <h1>Volunteer</h1>
          <p>Join our network of passionate volunteers and make a direct impact.
          </p>
          </Col>
          <Col md={4} className={style.movedonate}>
          <h1>Spread the Word</h1>
          <p>Share our mission with your network: like, follow, share .</p>
          <div className={style.movesocial}>
          <Link href="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" target="_blank" rel="noreferrer">
              <img src="/fbsvg.svg" alt="fb"></img>
            </Link>
            <Link href="https://www.instagram.com/istandwithforgottenwomen/" target="_blank" rel="noreferrer">
              <img src="/instasvg.svg" alt="insta"></img>
            </Link>

            <Link href="https://x.com/4gottenwomen" target="_blank" rel="noreferrer">
              <img src="/twitternew.png" alt="twitter"></img>
            </Link>

            <Link href="https://www.youtube.com/@istandwiththeforgottenwomen" target="_blank" rel="noreferrer">
              <img src="/youtube.png" alt="youtube"></img>
            </Link>
            </div>
          </Col>
     
         
        
      </Row>


      <div className={style.paramove}>
        <h2>Our Greatest Need: Community of Faith</h2>
        <p>Churches are the difference-makers in our effort to in breaking the cycle of unplanned pregnancies that lead to abortion. Together, we can make a real difference in the lives of women and couples wrestling with complex pregnancy decisions. 
        </p>
        <div className={style.movea}>
        <a href="/church/" >Learn more.</a>
        </div>
        </div>
        <div className={style.dataheading}>
    
     <div className={style.datatext}>
       <p>Stand with us in our mission to minimize the needless loss of life and the unnecessary emotional suffering that accompanies it.
       </p>
       <p> Together, we can make a real difference.</p>
     </div>
   
     </div>
  
      
        </div>
        </div>
          
        </div>
        </div>
   
  );
};

export default Movement;
