import React from "react";
import {  Col, Row } from "react-bootstrap";
import style from "./aboutus.module.css";

const Book = () => {
    return (

         
            <div className={style.content}>
             
                 <Row className="m-0">
                  <Col md={5} >
                  <img className={style.bookimagecontainer} src="/abortionbook.png" alt="book" />
                  </Col>
                  <Col md={7}>
                  <div  className={style.booktext}>
                  <h1>The most divisive issue since the abolition of slavery with profound ethical, societal and spiritual implications that demand pastoral leadership.</h1>
                
                    <p><span>54% </span>of women who have had an abortion identify as Catholic or Protestant. <span>40%</span> attended church regularly at the time of their first abortion. <span>76% </span>felt that their church couldn’t help.<span>There’s a significant and troubling issue in the church.</span> </p>
                
                  <div className={style.booklink}>
                  <div className={style.bookbutton} >
                    <a href="https://9eb34e-77.myshopify.com/collections/all" target="_blank">
                  <button className="mt-0">Order Now</button>
                  </a>
                  </div>
                  <a href="/book/">Learn more</a>
                  </div>

                  </div>
                  </Col>
                 </Row>
         
         </div>
        // </div>
      );
};

export default Book;
